import { ChakraProvider } from '@chakra-ui/react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ThemeName } from 'src/types/Theme.type';
import { getThemeStyle } from 'src/themes';
import { LOCAL_STORAGE_NAME } from 'src/constants/storage';
import { colorPalette } from 'src/themes/styles';

const themeDefault: ThemeName = "blue"

const ThemeContext = createContext<{
  theme: ThemeName;
  changeTheme?: (themeColor: ThemeName) => void;
}>({
  theme: themeDefault,
  changeTheme: undefined
});

type CProps = {
  children: React.ReactNode;
};
export function ThemeProvider({ children }: CProps) {
  const [theme, setTheme] = useState<ThemeName>(themeDefault);
  const [loadedThemeStorage, setLoadedThemeStorage] = useState<boolean>(false)

  const changeTheme = (themeColor: ThemeName) => {
    if (colorPalette[themeColor]) {
      setTheme(themeColor)
      localStorage.setItem(LOCAL_STORAGE_NAME.theme, themeColor)
    }

  }

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_NAME.chakraTheme)
    const themeStorage: ThemeName | null = localStorage.getItem(LOCAL_STORAGE_NAME.theme) as ThemeName
    setLoadedThemeStorage(true);
    if (colorPalette[themeStorage]) {
      setTheme(themeStorage)
    } else {
      setTheme(themeDefault)
    }
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {
        loadedThemeStorage
          ?
          <ChakraProvider theme={getThemeStyle(theme)}>
            {children}
          </ChakraProvider>
          : <></>

      }

    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext);
