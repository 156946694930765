import React from 'react';
import { ISvg } from 'src/types/Svg.type';

const IcSend: React.FC<ISvg> = ({
  linearGradient = ['#6DD5F2', '#2950C3'],
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="22"
      viewBox="0 0 29 22"
      fill="none"
    >
      <path
        d="M1.77778 11.1806C0.539187 11.7349 0.654278 13.213 1.97508 13.6427L9.66421 16.1564V20.595C9.66421 21.3727 10.4644 22.0001 11.4563 22.0001C11.9879 22.0001 12.4921 21.8153 12.8319 21.4931L16.2298 18.3005L23.0202 20.5177C24.056 20.8571 25.2562 20.3243 25.4261 19.4564L28.9336 1.58149C29.0377 1.06158 28.7473 0.537361 28.1937 0.240879C27.6402 -0.0556031 26.9168 -0.0813842 26.3304 0.180723L1.77778 11.1806ZM4.63311 12.2763L23.349 3.89319L11.3138 14.4376L11.3796 14.4806L4.63311 12.2763ZM22.9982 18.279L13.8677 15.2927L25.6015 5.01037L22.9982 18.279Z"
        fill="url(#paint0_linear_103_2153)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_103_2153"
          x1="7.55448"
          y1="0.569057"
          x2="14.3762"
          y2="22.4868"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={linearGradient[0]} />
          <stop offset="1" stopColor={linearGradient[1]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IcSend;
