import BaseRequest from './BaseRequest';
import config from 'src/config';

const googleRedirectUri = `${window.location.origin}/google/callback`;

export interface LoginBasicAuthen {
  email: string;
  password: string;
}

export interface RequestResetPasswordBasicAuthen {
  email: string;
}

export interface ResetPasswordBasicAuthen {
  password: string;
  token: string;
}

export default class AuthRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.baseUrlApi;
  }

  getLoginGoogle() {
    const url = `/api/auth/google/url/`;
    return this.get(url, {
      redirect_uri: googleRedirectUri,
    });
  }
  getLoginAccessToken(payload: { code: string }) {
    const url = `/api/auth/google/login/`;
    return this.post(url, {
      redirect_uri: googleRedirectUri,
      ...payload,
    });
  }
  getUser() {
    const url = `/api/auth/me`;
    return this.get(url);
  }

  loginBasic(payload: LoginBasicAuthen) {
    const url = '/api/client/basic-authentication/login'
    return this.post(url, payload);
  }

  requestResetPassword(payload: RequestResetPasswordBasicAuthen) {
    const url = '/api/client/basic-authentication/requestResetPassword'
    return this.post(url, payload);
  }

  resetPassword(payload: ResetPasswordBasicAuthen) {
    const url = '/api/client/basic-authentication/resetPassword'
    return this.post(url, payload);
  }
}
