import { Box, Flex, Image, Spinner, Text } from '@chakra-ui/react';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { icPlus } from 'src/assets/icon';
import AppButton from 'src/components/AppButton';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { fetchChatConversations } from 'src/store/chat';
import styles from 'src/styles/layout/AppLayout.module.scss';
import { getNavigateRoute } from 'src/utils/chat';
import ClearConversation from './component/clearConversation';
import Logout from './component/logout';
import IcChat from 'src/components/icons/IcChat';
import { useTranslation } from 'react-i18next';
import { LogoSingle } from 'src/assets/images';

type Props = {
  isDrawer?: boolean;
};

const Navbar: React.FC<Props> = ({ isDrawer }) => {
  const { data, loading, currentAI } = useAppSelector((state) => state.chat);
  const { t } = useTranslation()

  const navigate = useNavigate();
  const { chat_id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const addNewChat = async () => {
    navigate(getNavigateRoute(currentAI.source, currentAI.bot_id));
  };

  const scrollToChatLink = (id: string) => {
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView();
    }, 200);
  };

  const handleNavigate = () => {
    if (pathname === '/') {
      window.location.reload();
    }
    navigate('/');
  };

  useEffect(() => {
    if (!currentAI.bot_id) return;
    dispatch(fetchChatConversations(currentAI));
  }, [dispatch, currentAI]);

  useEffect(() => {
    if (chat_id) {
      scrollToChatLink(`app-button-${chat_id}`);
    }
  }, [chat_id]);

  return (
    <Flex
      flexDirection={'column'}
      className={cn(styles.navbar)}
      style={{
        borderRadius: isDrawer ? '0px' : '20px',
        top: isDrawer ? '0px' : '60px',
      }}
    >
      <Box
        onClick={handleNavigate}
        display={'flex'}
        justifyContent={'center'}
        p={'30px'}
        className={cn(styles.logo)}
      >
        <Image src={LogoSingle} w={'56px'} cursor={'pointer'} />
        <Text>{t('home.title')}</Text>
      </Box>
      <Box p={'30px'}>
        <AppButton
          size={'md'}
          width={'full'}
          variant="border"
          justifyContent="start"
          onClick={addNewChat}
        >
          <Image src={icPlus} mr={2} />
          {t('home.newChat')}
        </AppButton>
      </Box>

      <Box
        flex={'1 1 0'}
        overflowY={'auto'}
        maxH={'full'}
        px={'30px'}
        pb={'30px'}
      >
        {!loading ? (
          data?.map((chatItem) => (
            <AppButton
              key={`app-button-${chatItem?._id}`}
              id={`app-button-${chatItem?._id}`}
              borderRadius={'6px'}
              size={'md'}
              width={'full'}
              variant={chat_id === chatItem._id ? 'primary' : 'no-effects'}
              justifyContent="start"
              mb={3}
              className={cn(styles.btn_chat)}
              onClick={() => {
                navigate(
                  getNavigateRoute(
                    currentAI.source,
                    currentAI.bot_id,
                    chatItem._id,
                  ),
                );
              }}
            >
              <Image as="div" alt="icon" mr={2}>
                <IcChat stroke={chat_id === chatItem._id ? 'white' : 'black'} />
              </Image>
              <Text
                m={0}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
              >
                {chatItem?.messages?.[0]?.content || 'New chat'}
              </Text>
            </AppButton>
          ))
        ) : (
          <Flex justifyContent={'center'} alignItems={'center'} height={100}>
            <Spinner color="#fff" />
          </Flex>
        )}
      </Box>
      <Box className={cn(styles.btnWrapper)}>
        <Flex
          className={cn(styles.btn)}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          direction={'column'}
          gap={4}
        >
          {/* <button>
            <Image src={icUser} pr={2} alt="icUser" />
            Upgrade to Plus
          </button>
          <span>New</span> */}
          {/* <button onClick={confirmClearAllConversation}>
            <Image src={icDelete} pr={2} alt="icDelete" />
            Clear conversations
          </button> */}
          <ClearConversation />
          <Logout />
        </Flex>
      </Box>

      {/* <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete all conversations
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={clearAllConversation} ml={3} isLoading={isDeleting} loadingText="Deleting">
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog> */}
    </Flex>
  );
};
export default Navbar;
