import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';
import { ColorPalette } from 'src/types/Theme.type';

export const globalStyles = {
  colors: {
    brand: {
      100: '#E9E3FF',
      200: '#422AFB',
      300: '#422AFB',
      400: '#7551FF',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#11047A',
    },
    brandScheme: {
      100: '#E9E3FF',
      200: '#7551FF',
      300: '#7551FF',
      400: '#7551FF',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#02044A',
    },
    brandTabs: {
      100: '#E9E3FF',
      200: '#422AFB',
      300: '#422AFB',
      400: '#422AFB',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#02044A',
    },
    secondaryGray: {
      100: '#E0E5F2',
      200: '#E1E9F8',
      300: '#F4F7FE',
      400: '#E9EDF7',
      500: '#8F9BBA',
      600: '#A3AED0',
      700: '#707EAE',
      800: '#707EAE',
      900: '#1B2559',
    },
    red: {
      100: '#FEEFEE',
      500: '#EE5D50',
      600: '#E31A1A',
    },
    blue: {
      50: '#EFF4FB',
      500: '#3965FF',
    },
    orange: {
      100: '#FFF6DA',
      500: '#FFB547',
    },
    green: {
      100: '#E6FAF5',
      500: '#01B574',
    },
    navy: {
      50: '#d0dcfb',
      100: '#aac0fe',
      200: '#a3b9f8',
      300: '#728fea',
      400: '#3652ba',
      500: '#1b3bbb',
      600: '#24388a',
      700: '#1B254B',
      800: '#111c44',
      900: '#0b1437',
    },
    gray: {
      50: '#FAFCFE',
      100: '#f2f2f2',
      200: '#3d5976',
      300: '#274667',
    },
    border: {
      normal: '#cbcbcb',
    },
    card: {
      100: '#101530',
    },
    primary: {
      100: '#239ECA',
    },
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        overflowX: 'hidden',
        bg: 'white',
        fontFamily: 'Inter',
        letterSpacing: '-0.5px',
      },
      input: {
        color: 'gray.700',
      },
      html: {
        fontFamily: 'Inter',
      },
      '*::placeholder': {
        color: mode('gray.400', 'whiteAlpha.400')(props),
      },
      '*, *::before, &::after': {
        borderColor: mode('gray.200', 'whiteAlpha.300')(props),
        wordWrap: 'break-word',
      },
      '.table-temaplate': {
        table: {
          fontVariantNumeric: 'lining-nums tabular-nums',
          borderCollapse: 'collapse',
          w: 'full',
          th: {
            fontFamily: 'heading',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: 'wider',
            textAlign: 'start',
            paddingInlineStart: 6,
            paddingInlineEnd: 6,
            pt: 3,
            pb: 3,
            lineHeight: '4',
            fontSize: 'xs',
            borderBottom: '1px',
            borderColor: mode('gray.50', 'gray.700')(props),
          },
          td: {
            paddingInlineStart: 6,
            paddingInlineEnd: 6,
            paddingTop: 4,
            paddingBottom: 4,
            lineHeight: 5,
            borderBottom: '1px',
            borderColor: mode('gray.50', 'gray.700')(props),
          },
        },
      },
    }),
  },
};

export const colorPalette: ColorPalette = {
  pink: {
    brand: {
      900: '#A61E4D',
      800: '#C2255C',
      700: '#D6336C',
      600: '#E64980',
      500: '#F06595',
      400: '#F783AC',
      300: '#FAA2C1',
      200: '#FCC2D7',
      100: '#FFDEEB',
      50: '#FFF0F6',
    },
    primary: '#F06595',
    secondary: '#239ECA',
  },
  orange: {
    brand: {
      900: '#D9480F',
      800: '#E8590C',
      700: '#F76707',
      600: '#FD7E14',
      500: '#FF922B',
      400: '#FFA94D',
      300: '#FFC078',
      200: '#FFD8A8',
      100: '#FFE8CC',
      50: '#FFF4E6',
    },
    primary: '#FF922B',
    secondary: '#239ECA',
  },
  green: {
    brand: {
      900: '#087F5B',
      800: '#099268',
      700: '#0CA678',
      600: '#12B886',
      500: '#20C997',
      400: '#38D9A9',
      300: '#63E6BE',
      200: '#96F2D7',
      100: '#C3FAE8',
      50: '#E6FCF5',
    },
    primary: '#20C997',
    secondary: '#239ECA',
  },
  cyan: {
    brand: {
      900: '#0B7285',
      800: '#0C8599',
      700: '#1098AD',
      600: '#15AABF',
      500: '#22B8CF',
      400: '#3CC9DB',
      300: '#66D9E8',
      200: '#99E9F2',
      100: '#C5F6FA',
      50: '#E3FAFC',
    },
    primary: '#3CC9DB',
    secondary: '#239ECA',
  },
  blue: {
    brand: {
      900: '#184FC7',
      800: '#1971C2',
      700: '#1C7ED6',
      600: '#228BE6',
      500: '#339AF0',
      400: '#4DABF7',
      300: '#74C0FC',
      200: '#A5D8FF',
      100: '#D0EBFF',
      50: '#E7F5FF',
    },
    primary: '#4DABF7',
    secondary: '#239ECA',
  },
  gray: {
    brand: {
      900: '#1A1A1C',
      800: '#343A40',
      700: '#495057',
      600: '#868E96',
      500: '#ADB5BD',
      400: '#CED4DA',
      300: '#DEE2E6',
      200: '#E9ECEF',
      100: '#F1F3F5',
      50: '#F8F9FA',
    },
    primary: '#ADB5BD',
    secondary: '#239ECA',
  },
};
