import * as yup from 'yup';
import isEmailValidator from 'validator/lib/isEmail';

export const emailValidation = yup
  .string()
  .trim()
  .required('Email is required')
  .email('Invalid email address')
  .test(
    'is-valid',
    () => `Email is invalid`,
    (value) =>
      value
        ? isEmailValidator(value)
        : new yup.ValidationError('Invalid value'),
  );
