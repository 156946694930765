import { forwardRef } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { StyleProps } from '@chakra-ui/system';
import styles from 'src/styles/components/Button.module.scss';
import cn from 'classnames'

interface AppButtonProps extends ButtonProps {
  variant?:
  | 'brand'
  | 'darkBrand'
  | 'lightBrand'
  | 'light'
  | 'outline'
  | 'outlineGradient'
  | 'no-effects'
  | 'primary'
  | 'secondary'
  | 'gray'
  | 'transparent'
  | 'border'
}

const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>(
  (props, ref) => {
    const { variant = 'brand', children, ...rest } = props;
    const isShowBorderGradient = ["brand", "outlineGradient"].includes(variant)
    return (
      <Button {...rest} variant={variant} ref={ref} className={isShowBorderGradient ? cn(styles.button_border_gradient) : ''}>
        {children}
      </Button>
    );
  },
);

export default AppButton;

export const appButtonStyles = {
  baseStyle: {
    borderRadius: '6px',
    boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
    transition: '.25s all ease',
    boxSizing: 'border-box',
    fontSize: '16px',
    fontWeight: 500,
    _focus: {
      boxShadow: 'none',
    },
    _active: {
      boxShadow: 'none',
    },
  },
  variants: {
    outline: () => ({
      borderRadius: '6px',
      borderColor: 'border.normal',
      color: 'secondaryGray.900',
      _active: {
        background: "brand.100",
      },
    }),
    outlineGradient: () => ({
      borderRadius: "6px",
      border: "3px solid var(brand.800, #228BE6)",
      background: "white",
      color: 'secondaryGray.900',
      _active: {
        background: "brand.100",
      },
    }),
    border: () => ({
      borderRadius: '6px',
      border: "3px solid #0085D0",
      _hover: {
        background: "brand.100",
      },
    }),
    brand: () => ({
      bgGradient: 'linear(to-b, brand.800, brand.400)',
      color: 'white',
      borderRadius: '6px',
      border: "3px solid transparent"
    }),
    transparent: () => ({
      color: 'primary',
      borderRadius: '6px',
      background: "transparent",
    }),
    darkBrand: (props: StyleProps) => ({
      bg: mode('brand.900', 'brand.400')(props),
      color: 'white',
      _focus: {
        bg: mode('brand.900', 'brand.400')(props),
      },
      _active: {
        bg: mode('brand.900', 'brand.400')(props),
      },
      _hover: {
        bg: mode('brand.800', 'brand.400')(props),
        _disabled: {
          bg: mode('brand.800', 'brand.400')(props),
        },
      },
    }),
    lightBrand: (props: StyleProps) => ({
      bg: mode('#F2EFFF', 'whiteAlpha.100')(props),
      color: mode('brand.500', 'white')(props),
      _focus: {
        bg: mode('#F2EFFF', 'whiteAlpha.100')(props),
      },
      _active: {
        bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
      },
      _hover: {
        bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
        _disabled: {
          bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
        },
      },
    }),
    light: (props: StyleProps) => ({
      bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
      color: mode('secondaryGray.900', 'white')(props),
      _focus: {
        bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
      },
      _active: {
        bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
      },
      _hover: {
        bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
        _disabled: {
          bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
        },
      },
    }),
    primary: (props: StyleProps) => ({
      bg: mode('primary', 'primary')(props),
      color: 'white',
      _focus: {
        bg: mode('primary', 'primary')(props),
      },
      _active: {
        bg: mode('primary', 'primary')(props),
      },
      _hover: {
        bg: mode('primary', 'primary')(props),

        _disabled: {
          bg: mode('primary', 'primary')(props),
        },
      },
    }),
    secondary: () => ({
      bg: 'brand.200',
      color: 'secondaryGray.900',
      _focus: {
        bg: 'brand.300',
      },
      _active: {
        bg: 'brand.300',
      },
      _hover: {
        bg: 'brand.300',

        _disabled: {
          bg: 'brand.300',
        },
      },
    }),
    gray: () => ({
      bg: 'gray.100',
      color: '#444444',
      _focus: {
        bg: 'gray.100',
      },
      _active: {
        bg: 'gray.100',
      },
      _hover: {
        bg: 'gray.100',
        _disabled: {
          bg: 'gray.100',
        },
      },
    }),
  },
}

