import type { DrawerProps } from 'antd';
import { Drawer } from 'antd';
import cn from 'classnames';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { fetchChatConversations } from 'src/store/chat';
import styles from 'src/styles/layout/AppLayout.module.scss';
import Navbar from './Navbar';

const DrawerNavbar = (props: DrawerProps) => {
  const { currentAI } = useAppSelector((state) => state.chat);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!currentAI.bot_id) return;
    dispatch(fetchChatConversations(currentAI));
  }, [dispatch, currentAI]);

  return (
    <Drawer {...props} className={cn(styles.drawerWrapper)}>
      <Navbar isDrawer={true} />
    </Drawer>
  );
};
export default DrawerNavbar;
