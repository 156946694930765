/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Image, useToast } from '@chakra-ui/react';
import cn from 'classnames';
import { useState } from 'react';
import { LogoSingle } from 'src/assets/images';
import { AuthLayout } from 'src/layouts';
import rf from 'src/requests/RequestFactory';
import styles from 'src/styles/page/Login.module.scss';
// import EmailForm from './form/EmailForm';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import ResetForm from './form/ResetForm';

function ResetPassword() {
  const toast = useToast();
  const navigate = useNavigate();

  const [loadingResetPassword, setLoadingResetPassword] =
    useState<boolean>(false);

  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const token = parsed.token;

  const onSubmit = async (values: any) => {
    try {
      if (!token) {
        return;
      }
      setLoadingResetPassword(true);

      await rf.getRequest('AuthRequest').resetPassword({
        token,
        password: values.password,
      });

      toast({
        title: 'Reset Password!',
        description:
          'Your password has been successfully reset. You can use it to login.',
        status: 'success',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });

      navigate('/login');
    } catch (error) {
      toast({
        title: 'Reset Password failed!',
        description:
          'Reset password error. Please check your email and try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoadingResetPassword(false);
    }
  };

  const showUI = () => {
    return <ResetForm onSubmit={onSubmit} isLoading={loadingResetPassword} />;
  };

  return (
    <AuthLayout>
      <Box className={cn(styles.containerWrapper)}>
        <Image
          mx={'auto'}
          w={{ base: '40px', sm: 'auto' }}
          src={LogoSingle}
          pr={2}
          alt="logo"
        />
        {showUI()}
      </Box>
    </AuthLayout>
  );
}

export default ResetPassword;
