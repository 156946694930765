import { colorPalette } from 'src/themes/styles';
import { ThemeName } from 'src/types/Theme.type';

export const HomeGrid = {
  example: [
    '“Explain quantum computing in simple terms”',
    'Remembers what user said earlier in the conversation',
    'May occasionally generated incorrect information',
  ],
  capabilities: [
    '“Got any creative ideas for a 10 year old’s birthday?”',
    'Allows user to provide follow-up corrections',
    'May occasionally produce harmful instructions or biased content',
  ],
  limitations: [
    '“How do i male an HTTP request in Javascript?”',
    'Trained to decline inappropriate requests',
    'Limited knowledge of world and events after 2021',
  ],
};

export const themes = () => {
  const themeOptions: {
    themeName: ThemeName;
    thumb: string[];
  }[] = [];
  for (const [key, theme] of Object.entries(colorPalette)) {
    themeOptions.push({
      themeName: key as ThemeName,
      thumb: [theme.brand[600], theme.brand[300]],
    });
  }
  return themeOptions;
};
