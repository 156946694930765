import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import AppButton from 'src/components/AppButton';
import styles from 'src/styles/page/Login.module.scss';
import * as yup from 'yup';
import PasswordSvg from '../../../assets/images/password.svg';

type Props = {
  // eslint-disable-next-line
  onSubmit: (values: any) => void;
  isLoading: boolean;
};

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(20, 'Password must be at most 20 characters'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(20, 'Password must be at most 20 characters')
    .oneOf([yup.ref('password')], 'Confirm passwords must match'),
});

const ResetForm = ({ onSubmit, isLoading = false }: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isPasswordView, setIsPasswordView] = useState<boolean>(false);
  const [isConfirmPasswordView, setConfirmPasswordView] =
    useState<boolean>(false);

  const onChangeView = () => {
    setIsPasswordView(!isPasswordView);
  };

  const onChangeConfirmPasswordView = () => {
    setConfirmPasswordView(!isConfirmPasswordView);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <Text
            className={cn(styles.title)}
            pt={4}
            pb={0}
            mb={4}
            textAlign={'center'}
          >
            Reset your password
          </Text>
          <Text py={2} className={cn(styles.description)} textAlign={'center'}>
            Enter a new password below to change your password
          </Text>
          <FormControl isInvalid={!!errors.password}>
            <InputGroup
              size="md"
              className={cn(styles.inputGroup)}
              mt={8}
            >
              <InputLeftElement
                className={cn(styles.inputIcon)}
                pointerEvents="none"
              >
                <img src={PasswordSvg} alt="email icon" />
              </InputLeftElement>
              <Input
                className={cn(styles.input)}
                type={isPasswordView ? 'text' : 'password'}
                variant="outline"
                placeholder="New password"
                {...register('password')}
              />
              <InputRightElement cursor={'pointer'} height="100%" mr="1rem">
                {isPasswordView ? (
                  <ViewIcon color="gray.200" onClick={onChangeView} />
                ) : (
                  <ViewOffIcon color="gray.200" onClick={onChangeView} />
                )}
              </InputRightElement>
            </InputGroup>{' '}
            <FormErrorMessage>
              {errors?.password?.message as string}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.confirmPassword}>
            <InputGroup
              size="md"
              className={cn(styles.inputGroup)}
              mt={8}
            >
              <InputLeftElement
                className={cn(styles.inputIcon)}
                pointerEvents="none"
              >
                <img src={PasswordSvg} alt="email icon" />
              </InputLeftElement>
              <Input
                className={cn(styles.input)}
                type={isConfirmPasswordView ? 'text' : 'password'}
                variant="outline"
                placeholder="Confirm password"
                {...register('confirmPassword')}
              />
              <InputRightElement cursor={'pointer'} height="100%" mr="1rem">
                {isConfirmPasswordView ? (
                  <ViewIcon
                    color="gray.200"
                    onClick={onChangeConfirmPasswordView}
                  />
                ) : (
                  <ViewOffIcon
                    color="gray.200"
                    onClick={onChangeConfirmPasswordView}
                  />
                )}
              </InputRightElement>
            </InputGroup>{' '}
            <FormErrorMessage>
              {errors?.confirmPassword?.message as string}
            </FormErrorMessage>
          </FormControl>

          <AppButton
            borderRadius={'8px'}
            size="md"
            width={'full'}
            mt={6}
            variant="primary"
            mb={3}
            type="submit"
            className={cn(styles.btnSubmit, styles.btnContinue)}
            isLoading={isLoading}
            loadingText="Sending"
          >
            Reset Password
          </AppButton>
        </>
      </form>
    </Box>
  );
};

export default ResetForm;
