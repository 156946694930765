import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Image,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppButton from 'src/components/AppButton';
import { IcLogout } from 'src/components/icons';
import Storage from 'src/utils/storage';

const Logout = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const cancelRef = React.useRef() as any;
  const theme = useTheme();

  const handleLogout = () => {
    Storage.clearAccessToken();
    navigate('/login');
  };

  const confirmLogout = () => {
    onOpen();
  };

  return (
    <>
      <AppButton variant="transparent" onClick={confirmLogout} p={0}>
        <Image as="div" pr={3.5} alt="icLogout">
          <IcLogout stroke={theme.colors.primary} />
        </Image>
        {t('logout.title')}
      </AppButton>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent m={4}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('logout.title')}
            </AlertDialogHeader>

            <AlertDialogBody>{t('logout.content')}</AlertDialogBody>

            <AlertDialogFooter>
              <AppButton variant="gray" ref={cancelRef} onClick={onClose}>
                {t('common.button.cancel')}
              </AppButton>
              <AppButton variant="primary" onClick={handleLogout} ml={3}>
                {t('logout.button.accept')}
              </AppButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Logout;
