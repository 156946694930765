import { Box, Flex, Image, Spinner } from '@chakra-ui/react';
import cn from 'classnames';
import { useEffect, FC, useState, useMemo } from 'react';
import { icClose } from 'src/assets/icon';
import config from 'src/config';
import styles from 'src/styles/page/Chat.module.scss';
import PdfViewer from '../pdf-viewer/PdfViewer';
import { useTranslation } from 'react-i18next';

interface PDFTabProps {
  fileName: string;
  botId: string;
  isMobile?: boolean;
  isLoading: boolean;
  page?: number;
  onClose?: () => void;
}

const domainApi = config.api.baseUrlApi;

const PDFTab: FC<PDFTabProps> = ({
  fileName,
  botId,
  isMobile = false,
  isLoading,
  page,
  onClose,
}) => {
  const { t } = useTranslation();
  const [random, setRandom] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const url = useMemo(() => {
    return page
      ? `${domainApi}/api/bots/${botId}/content/${fileName}#page=${page}`
      : `${domainApi}/api/bots/${botId}/content/${fileName}`;
  }, [page, botId, fileName]);

  const resetIframe = () => {
    setRandom(random + 1);
  };

  useEffect(() => {
    resetIframe();
  }, [page]);

  useEffect(() => {
    setLoading(true);
  }, [url, page]);

  useEffect(() => {
    if (isLoading) setLoading(true);
  }, [isLoading]);

  const handleLoaded = () => {
    setLoading(false);
  };

  return (
    <Flex flexDir={'column'} w={'full'} h={'full'}>
      {onClose && (
        <Box cursor={'pointer'} paddingInline={4} pb={4}>
          <Image src={icClose} onClick={onClose} alt="close" />
        </Box>
      )}
      <Box bg="white" flex={'1 1 0'} position={'relative'}>
        {isMobile && !isLoading ? (
          <PdfViewer url={url} />
        ) : !isLoading ? (
          <>
            <embed
              id="pdfDoc"
              key={random}
              src={url}
              type="application/pdf"
              style={{
                width: loading ? '0' : '100%',
                height: loading ? '0' : '100%',
              }}
              onLoad={handleLoaded}
            />
            {loading && (
              <Flex
                alignItems={'center'}
                color="gray"
                position={'absolute'}
                className={cn(styles.loadingPDF)}
              >
                <Spinner mr={4} /> {t('pdfViewer.loading')}
              </Flex>
            )}
          </>
        ) : (
          <Flex
            alignItems={'center'}
            color="gray"
            position={'absolute'}
            className={cn(styles.loadingPDF)}
          >
            <Spinner mr={4} /> {t('pdfViewer.loading')}
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default PDFTab;
