/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Flex,
  IconButton,
  Image,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  Textarea,
  useTheme,
} from '@chakra-ui/react';
import cn from 'classnames';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import // icDislike,
// icLike,
// icNotepad,
// icRefresh,
'src/assets/icon';
import rf from 'src/requests/RequestFactory';
// import AppButton from 'src/components/AppButton';
import styles from 'src/styles/page/Chat.module.scss';
import { ERole, IChatGptDetail, IMessage } from 'src/types';
import { useAppDispatch } from 'src/hooks/hooks';
import { setDataChatGpt } from 'src/store/chat';
import MessageCard from 'src/components/MessageCard';
import { LOADING_CHAT } from 'src/constants';
import { IcSend } from 'src/components/icons';

const CHAT_INPUT_CONTAINER = 'chat-input-container';
const CHAT_MESSAGE_ID = `chat-message`;

const getChatMessageId = (index: number) => {
  return `${CHAT_MESSAGE_ID}-${index}`;
};

function Chat() {
  const [input, setInput] = useState<string>('');
  const ref = useRef<any>(null);
  const refBottomDiv = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [chatInputHeight, setChatInputHeight] = useState(0);

  const theme = useTheme();

  const [detailData, setDetailData] = useState<IChatGptDetail>({
    _id: '',
    temperature: 0,
    model_type: '',
    source: '',
    user_id: '',
    messages: [],
  });
  const dispatch = useAppDispatch();

  const { chat_id } = useParams();

  const scrollToBottom = () => {
    refBottomDiv.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToMessage = (id: string) => {
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 0);
  };

  const fetchChatConversationId = useCallback(async () => {
    setLoading(true);
    try {
      const res = await rf
        .getRequest('ChatRequest')
        .getChatConversationById(chat_id);

      setDetailData(res.data);
    } catch (e) {
      console.log(e, 'Error');
    }
    scrollToBottom();
    setLoading(false);
  }, [chat_id]);

  const updateNavbarList = async () => {
    try {
      const res = await rf.getRequest('ChatRequest').getChatConversations();
      const { data } = res;
      dispatch(setDataChatGpt({ data }));
    } catch (e) {
      console.log(e, 'Error');
    }
  };

  const sendChatConversation = async () => {
    setLoading(true);
    setInput('');

    const newMessageByUser = [
      ...detailData?.messages,
      {
        role: ERole.USER,
        content: input,
      },
    ];
    setDetailData((pre: IChatGptDetail) => {
      return {
        ...pre,
        messages: [
          ...newMessageByUser,
          {
            role: ERole.ASSISTANT,
            content: LOADING_CHAT,
          },
        ],
      };
    });
    try {
      const res = await rf.getRequest('ChatRequest').sendChatMessage({
        content: input,
        conversation_id: chat_id,
      });
      const { answer } = res.data;

      setDetailData((pre: IChatGptDetail) => {
        return {
          ...pre,
          messages: [
            ...newMessageByUser,
            {
              role: ERole.ASSISTANT,
              content: answer,
            },
          ],
        };
      });

      if (!detailData.messages.length) {
        updateNavbarList();
      }
    } catch (e) {
      setDetailData((pre: IChatGptDetail) => {
        return {
          ...pre,
          messages: [
            ...newMessageByUser,
            {
              role: ERole.ASSISTANT,
              content: 'Sorry, I have some problems',
            },
          ],
        };
      });
    }

    scrollToMessage(getChatMessageId(newMessageByUser.length - 1));

    setLoading(false);
  };

  useLayoutEffect(() => {
    setChatInputHeight(
      document.getElementById(CHAT_INPUT_CONTAINER)?.offsetHeight || 0,
    );
  }, []);

  useEffect(() => {
    if (ref) {
      ref.current.focus();
    }
  }, [ref, chat_id]);

  useEffect(() => {
    fetchChatConversationId();
  }, [fetchChatConversationId]);

  return (
    <Box
      className={cn(styles.chatContainer)}
      style={
        {
          '--chat-input-container': `${chatInputHeight}px`,
        } as React.CSSProperties
      }
    >
      <Flex className={cn(styles.chatWrapper)}>
        <Box position={'relative'} w={{ base: 'full' }}>
          <Box pt={'30px'} px={'20px'} className={cn(styles.chatContent)}>
            {detailData?.messages?.map((el: IMessage, index) => (
              <MessageCard
                message={el}
                key={detailData._id + index + el.role}
                id={getChatMessageId(index)}
              />
            ))}
            <Box ref={refBottomDiv} />
          </Box>
        </Box>
      </Flex>
      <Box className={cn(styles.bottomWrapper)} id={CHAT_INPUT_CONTAINER}>
        {/* <Flex justifyContent={'center'}>
              <AppButton
                borderRadius={'6px'}
                size={'lg'}
                variant="gray"
                className={cn(styles.btn_login)}
                mx={'auto'}
              >
                <Image src={icRefresh} mr={2} alt="icon" /> Regenerate response
              </AppButton>
            </Flex> */}
        <Box className={cn(styles.inputWrapper)}>
          <Box maxW={'full'} mx={'auto'}>
            <InputGroup size={'lg'} className={cn(styles.inputGroup)} mt={8}>
              <InputRightElement pr={4}>
                <IconButton
                  variant="outline"
                  colorScheme="teal"
                  aria-label="Send "
                  isDisabled={loading || !input}
                  onClick={sendChatConversation}
                  className={cn(styles.btnSend)}
                  icon={
                    loading ? (
                      <Spinner color={theme.colors.brand[600]} />
                    ) : (
                      <Image as="div" alt="icon">
                        <IcSend
                          linearGradient={[
                            theme.colors.brand[600],
                            theme.colors.brand[300],
                          ]}
                        />
                      </Image>
                    )
                  }
                />
              </InputRightElement>
              <Textarea
                ref={ref}
                autoFocus={true}
                className={cn(styles.input)}
                variant="filled"
                placeholder="Ask me anything..."
                resize="none"
                value={input}
                onChange={(event) => {
                  setInput(event.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && input && !loading) {
                    e.preventDefault();
                    sendChatConversation();
                  }
                }}
              />
            </InputGroup>
            <Text pt={4} className={cn(styles.inputDescription)}>
              Free research Previe. Chatbot may produce inaccurated information
              about people, places, or facts.{' '}
              <span>Chatbot may 12 Version.</span>
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Chat;
