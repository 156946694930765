import { Box, Flex } from '@chakra-ui/react';
import cn from 'classnames';
import React, { FC } from 'react';
import { LOADING_CHAT } from 'src/constants';
import 'src/styles/components/ChatCard.scss';
import styles from 'src/styles/page/Chat.module.scss';
import { ERole, IMessage } from 'src/types';
import { parseAnswerToHtml, parseMessage } from 'src/utils/chat';
import MessageLoader from './MessageLoader';
import { useTranslation } from 'react-i18next';

interface CProps {
  message: IMessage;
  onOpenPDF?: (fileName: string) => void;
  id?: string;
  fullWidth?: boolean;
}

const getFileTemplateId = (file: string) => {
  return `file-message-${file}`;
};

const MessageCard: FC<CProps> = ({ message, onOpenPDF, id, fullWidth }) => {
  const { t } = useTranslation();
  const isChatBox = ERole.ASSISTANT === message.role;
  const { citations, fragments } = parseAnswerToHtml(message.content);

  const messageContent = fragments?.map((el) =>
    citations.includes(el)
      ? `<span id='${getFileTemplateId(el)}' class='spanFile' >${
          citations.findIndex((e) => el === e) + 1
        }</span>`
      : el,
  );

  const handleOpenPdfFromParent = (event: React.MouseEvent<HTMLElement>) => {
    const elementId = (event.target as HTMLElement).id;
    const elementIndex = elementId
      ? citations.findIndex((e) => getFileTemplateId(e) === elementId)
      : -1;

    if (elementIndex !== -1) {
      onOpenPDF?.(citations[elementIndex]);
    }
  };

  return (
    <Box
      className={cn(styles.chatItem, {
        [styles.chatBox]: isChatBox,
        [styles['chatItem--full']]: fullWidth,
      })}
      id={id}
    >
      <Flex gap={6} className={cn(styles.chatItem_content)}>
        <Box flex={1}>
          {message.content === LOADING_CHAT ? (
            <MessageLoader />
          ) : (
            <div
              onClick={(e) => handleOpenPdfFromParent(e)}
              dangerouslySetInnerHTML={{
                __html: parseMessage(messageContent.join('')),
              }}
            />
          )}
        </Box>
      </Flex>
      {citations?.length ? (
        <Box className={cn(styles.pdfContainer)}>
          <span>{t('messageCard.references')} </span>
          {citations?.map((el, index) => (
            <Box
              key={'chat' + el + index}
              onClick={() => {
                onOpenPDF?.(el);
              }}
              className={cn(styles.linkPdf)}
            >
              {index + 1}. {el}
            </Box>
          ))}
        </Box>
      ) : null}
      {/* <Flex justifyContent={'flex-end'}>
        {isChatBox && (
          <Flex gap={3} height={'22px'}>
            <Image src={icNotepad} alt="icon" />
            <Image src={icLike} alt="icon" />
            <Image src={icDislike} alt="icon" />
          </Flex>
        )}
      </Flex> */}
    </Box>
  );
};

export default MessageCard;
