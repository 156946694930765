import { colorPalette } from './styles';
import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { globalStyles } from 'src/themes/styles';
import { appButtonStyles } from 'src/components/AppButton';
import { cardStyles } from 'src/components/AppCard';
import { ThemeName, ThemeColor } from 'src/types/Theme.type';

// Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  tablet: '1180px',
  xl: '1200px',
  '2xl': '1536px',
};

export function getThemeStyle(theme: ThemeName = 'blue') {
  let getThemeColor: ThemeColor = colorPalette[theme];
  if (!getThemeColor) {
    const themeColorDefault = colorPalette.blue;
    getThemeColor = themeColorDefault;
  }
  return extendTheme({
    config,
    styles: globalStyles.styles,
    colors: { ...globalStyles.colors, ...getThemeColor },
    components: {
      Button: appButtonStyles,
      Card: cardStyles,
      // Input: appInputStyles,
    },
    breakpoints,
  });
}
