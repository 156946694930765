import { Flex, FlexProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useTheme } from 'src/context/ThemeContext';
import { ThemeName } from 'src/types/Theme.type';

type BackgroundContainerProps = {
  theme: ThemeName;
};

export default function AuthBackground(props: FlexProps) {
  const { theme } = useTheme();
  return (
    <BackgroundContainer theme={theme} {...props}>
      {props.children}
    </BackgroundContainer>
  );
}

const BackgroundContainer = styled(Flex)<BackgroundContainerProps>`
  width: 100vw;
  height: 100dvh;
  background-image: ${`url('/images/auth-background.png')`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;
