import React from 'react';
import { ISvg } from 'src/types/Svg.type';

const IcDelete: React.FC<ISvg> = ({ stroke = '#228BE6' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        d="M0.999975 3.68884H2.34442H13.1"
        stroke={stroke}
        strokeWidth="1.34444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7555 3.68889V13.1C11.7555 13.4566 11.6139 13.7985 11.3618 14.0507C11.1096 14.3028 10.7677 14.4444 10.4111 14.4444H3.68888C3.33231 14.4444 2.99034 14.3028 2.73821 14.0507C2.48608 13.7985 2.34443 13.4566 2.34443 13.1V3.68889M4.3611 3.68889V2.34444C4.3611 1.98788 4.50275 1.64591 4.75488 1.39378C5.00701 1.14165 5.34897 1 5.70554 1H8.39443C8.751 1 9.09297 1.14165 9.3451 1.39378C9.59723 1.64591 9.73888 1.98788 9.73888 2.34444V3.68889"
        stroke={stroke}
        strokeWidth="1.34444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.70552 7.05005V11.0834"
        stroke={stroke}
        strokeWidth="1.34444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.39443 7.05005V11.0834"
        stroke={stroke}
        strokeWidth="1.34444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcDelete;
