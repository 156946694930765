import { FC, useEffect, useState } from 'react';
import { Navbar } from 'src/layouts';
import cn from 'classnames';
import { Box, Hide, Show } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from 'src/styles/layout/AppLayout.module.scss';
import Header from './Header';
import DrawerNavbar from './DrawerNavbar';
import MenuPersonal from 'src/layouts/component/menuPersonal';
import HomeBackground from 'src/components/HomeBackground';

const AppLayout: FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { pathname } = useLocation();

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    onClose();
  }, [pathname]);

  return (
    <HomeBackground height={'100svh'} className={cn(styles.container)}>
      <Header
        openDrawer={openDrawer}
        onCloseDrawer={onClose}
        onShowDrawer={showDrawer}
      />
      <Show breakpoint="(min-width: 1180px)">
        <Navbar />
      </Show>
      <Box
        className={cn(styles.main)}
        pl={{ base: 0, tablet: '322px' }}
        maxHeight={{ base: '100svh' }}
        overflow={'hidden'}
      >
        <Hide breakpoint="(min-width: 1180px)">
          <DrawerNavbar
            width={'322px'}
            placement={'left'}
            closable={false}
            onClose={onClose}
            open={openDrawer}
            getContainer={false}
          />
        </Hide>
        <Box
          h={'100px'}
          display={'grid'}
          alignContent={'center'}
          justifyContent={'flex-end'}
          position={'absolute'}
          top={'0px'}
          right={'15px'}
          zIndex="10"
          className={cn(styles.containerMenuDropdown)}
        >
          <MenuPersonal />
        </Box>
        <Box height="100%" paddingTop="60px">
          <Outlet />
        </Box>
      </Box>
    </HomeBackground>
  );
};

export default AppLayout;
