/* eslint-disable @typescript-eslint/no-explicit-any */
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { emailValidation } from 'src/common/validationForm';
import AppButton from 'src/components/AppButton';
import styles from 'src/styles/page/Login.module.scss';
import * as yup from 'yup';
import EmailSvg from '../../../assets/images/email.svg';
import PasswordSvg from '../../../assets/images/password.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmit: (values: any) => void;
  onResetPassword: () => void;
  values: any;
  isLoading: boolean;
};

const schema = yup.object().shape({
  email: emailValidation,
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(20, 'Password must be at most 20 characters'),
});

const LoginForm = ({
  onSubmit,
  onResetPassword,
  values,
  isLoading = false,
}: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: values,
  });
  const { t } = useTranslation();

  const [isPasswordView, setIsPasswordView] = useState<boolean>(true);

  const onChangeView = () => {
    setIsPasswordView(!isPasswordView);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <Text className={cn(styles.title)} pt={4} textAlign={'center'}>
            {t('login.titleStep2')}
          </Text>
          <FormControl isInvalid={!!errors.email}>
            <InputGroup size="md" className={cn(styles.inputGroup)} mt={8}>
              <InputLeftElement
                className={cn(styles.inputIcon)}
                pointerEvents="none"
              >
                <img src={EmailSvg} alt="email icon" />
              </InputLeftElement>
              <Input
                className={cn(styles.input)}
                variant="outline"
                placeholder={t('login.email')}
                disabled
                {...register('email')}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors?.email?.message as string}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.password}>
            <InputGroup size="md" className={cn(styles.inputGroup)} mt={8}>
              <InputLeftElement
                className={cn(styles.inputIcon)}
                pointerEvents="none"
              >
                <img src={PasswordSvg} alt="email icon" />
              </InputLeftElement>
              <Input
                className={cn(styles.input)}
                type={!isPasswordView ? 'text' : 'password'}
                variant="outline"
                placeholder="Password"
                {...register('password')}
              />
              <InputRightElement cursor={'pointer'} height="100%" mr="1rem">
                {!isPasswordView ? (
                  <ViewIcon color="gray.200" onClick={onChangeView} />
                ) : (
                  <ViewOffIcon color="gray.200" onClick={onChangeView} />
                )}
              </InputRightElement>
            </InputGroup>{' '}
            <FormErrorMessage>
              {errors?.password?.message as string}
            </FormErrorMessage>
          </FormControl>

          <Text className={cn(styles.description)} pt={5}>
            <Box
              width={'-webkit-fit-content'}
              margin="auto"
              onClick={onResetPassword}
            >
              <Link className={cn(styles.link)} to="/login">
                {t('login.forgotPw')}
              </Link>
            </Box>
          </Text>
          <AppButton
            borderRadius={'6px'}
            size="md"
            width={'full'}
            type="submit"
            mt={6}
            variant="primary"
            className={cn(styles.btnSubmit, styles.btnContinue)}
            mb={0}
            isLoading={isLoading}
            loadingText={t('login.button.continue')}
          >
            {t('login.button.continue')}
          </AppButton>

          {/* <Text className={cn(styles.description)}>
            Don’t have an account?{' '}
            <Link className={cn(styles.link)} to={'/sign-up'}>
              Sign up
            </Link>
          </Text> */}
        </>
      </form>
    </Box>
  );
};

export default LoginForm;
