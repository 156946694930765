import React from 'react';

function IcMenuDropdown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
    >
      <path
        d="M6.47607 1.69528H24.4047"
        stroke="black"
        strokeWidth="1.73853"
        strokeLinecap="round"
      />
      <path
        d="M6.47607 18.4287H24.4047"
        stroke="black"
        strokeWidth="1.73853"
        strokeLinecap="round"
      />
      <path
        d="M6.47607 10.062H24.4047"
        stroke="black"
        strokeWidth="1.73853"
        strokeLinecap="round"
      />
      <circle cx="1.69524" cy="1.6953" r="1.19524" fill="black" />
      <circle cx="1.69524" cy="10.062" r="1.19524" fill="black" />
      <circle cx="1.69524" cy="18.4287" r="1.19524" fill="black" />
    </svg>
  );
}

export default IcMenuDropdown;
