import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Image,
  useDisclosure,
  useTheme,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AppButton from 'src/components/AppButton';
import { IcDelete } from 'src/components/icons';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import rf from 'src/requests/RequestFactory';
import { fetchChatConversations } from 'src/store/chat';

const ClearConversation = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();

  const { isOpen, onOpen, onClose } = useDisclosure();
  // eslint-disable-next-line
  const cancelRef = React.useRef() as any;

  const [isDeleting, setDeleting] = useState<boolean>(false);

  const { currentAI, data } = useAppSelector((state) => state.chat);

  const dispatch = useAppDispatch();

  const clearAllConversation = async () => {
    try {
      setDeleting(true);
      await rf.getRequest('ChatRequest').clearAllConversation({
        bot_id: currentAI.bot_id,
        source: currentAI.source,
      });

      onClose();

      toast({
        title: t('clearConversation.message.successTitle'),
        description: t('clearConversation.message.successDesc'),
        status: 'success',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });

      dispatch(fetchChatConversations(currentAI));

      if (params['chat_id'] && params['bot_id']) {
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split('/');
        const parentPath = pathParts.slice(0, -1).join('/');
        navigate(parentPath);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: t('clearConversation.message.failTitle'),
        description: t('clearConversation.message.failDesc'),
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setDeleting(false);
    }
  };

  const confirmClearAllConversation = async () => {
    onOpen();
  };

  return (
    <>
      <AppButton
        variant="transparent"
        onClick={confirmClearAllConversation}
        p={0}
      >
        <Image as="div" pr={3.5} alt="icDelete">
          <IcDelete stroke={theme.colors.primary} />
        </Image>
        {t('clearConversation.title')}
      </AppButton>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        {data.length ? (
          <AlertDialogOverlay>
            <AlertDialogContent m={4}>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {t('clearConversation.header')}
              </AlertDialogHeader>

              <AlertDialogBody>
                {t('clearConversation.confirmContent')}
              </AlertDialogBody>

              <AlertDialogFooter>
                <AppButton variant="gray" ref={cancelRef} onClick={onClose}>
                  {t('common.button.cancel')}
                </AppButton>
                <Button
                  colorScheme="red"
                  onClick={clearAllConversation}
                  ml={3}
                  isLoading={isDeleting}
                  loadingText="Deleting"
                >
                  {t('clearConversation.button.delete')}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        ) : (
          <AlertDialogOverlay>
            <AlertDialogContent m={4}>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {t('clearConversation.header')}
              </AlertDialogHeader>

              <AlertDialogBody>
                {t('clearConversation.noConversation')}
              </AlertDialogBody>

              <AlertDialogFooter>
                <AppButton variant="gray" ref={cancelRef} onClick={onClose}>
                  {t('common.button.close')}
                </AppButton>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        )}
      </AlertDialog>
    </>
  );
};

export default ClearConversation;
