import { Flex, FlexProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'src/context/ThemeContext';
import { PageName, ThemeName } from 'src/types/Theme.type';

type BackgroundContainerProps = {
  theme: ThemeName;
  page: PageName;
};

export default function HomeBackground(props: FlexProps) {
  const { theme } = useTheme();
  const { chat_id } = useParams();
  const page = chat_id ? 'chat' : 'home';
  return (
    <BackgroundContainer page={page} theme={theme} {...props}>
      {props.children}
    </BackgroundContainer>
  );
}

const BackgroundContainer = styled(Flex)<BackgroundContainerProps>`
  width: 100vw;
  height: 100dvh;
  background-image: ${(props: BackgroundContainerProps) =>
    `url('/images/${props.page}-background.png')`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;
