import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import styles from 'src/styles/layout/AppLayout.module.scss';
import cn from 'classnames';
import { IcMenuDropdown } from 'src/components/icons';
import { icAvatarAccount, icChevronDown, icPen } from 'src/assets/icon';
import { useAppSelector } from 'src/hooks/hooks';
import { themes } from 'src/constants';
import { useTheme } from 'src/context/ThemeContext';
import { ThemeName } from 'src/types/Theme.type';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import Storage from 'src/utils/storage';
import { Languages } from 'src/constants/language';
import { RadioChangeEvent } from 'antd/lib';

const themeOptions = themes();

const MenuPersonal = () => {
  const { i18n, t } = useTranslation();
  const { user } = useAppSelector((state) => state.myAccount);
  const { changeTheme } = useTheme();
  const defaultLang = Storage.getLanguage();

  const generateUsername = useMemo(() => {
    const username = user?.email.split('@')[0];
    const isTruncate = username && username.length > 40;
    if (isTruncate) return username.substring(0, 20) + '...';
    return username;
  }, [user]);

  const generateEmail = useMemo(() => {
    const isTruncate = user?.email && user.email.length > 40;
    if (isTruncate) return user.email.substring(0, 20) + '...';
    return user?.email;
  }, [user]);

  // const handleChangeTheme = (themeName: ThemeName) => {
  //   changeTheme && changeTheme(themeName);
  // };

  const handleChangeLanguage = ({ target: { value } }: RadioChangeEvent) => {
    i18n.changeLanguage(value);
    Storage.setLanguage(value);
  };

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        rightIcon={<IcMenuDropdown />}
        className={cn(styles.menuButton)}
        _active={{}}
        _hover={{}}
      >
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          overflow={'hidden'}
          gap={'8px'}
        >
          <Image src={icAvatarAccount} alt="icAvatarAccount" />
          <Flex
            direction={'column'}
            alignItems={'flex-start'}
            justifyContent={'center'}
            overflow={'hidden'}
          >
            <Text className={cn(styles.text, styles.username)}>
              {generateUsername}
            </Text>
            <Text className={cn(styles.text, styles.email)}>
              {generateEmail}
            </Text>
          </Flex>
        </Box>
      </MenuButton>
      <MenuList className={cn(styles.menuList)}>
        <MenuItem
          display={'flex'}
          flexDirection={'column'}
          gap={2}
          _active={{}}
        >
          <Box gap={2} w={'100%'}>
            <Radio.Group
              options={Languages}
              onChange={handleChangeLanguage}
              defaultValue={defaultLang}
              optionType="button"
              buttonStyle="solid"
            />
          </Box>
        </MenuItem>
        {/* <MenuItem
          display={'flex'}
          flexDirection={'column'}
          gap={2}
          _active={{}}
        >
          <Box
            display={'grid'}
            alignItems={'center'}
            gridTemplateColumns={'20px 1fr 20px'}
            gap={2}
            w={'100%'}
          >
            <Image src={icPen} alt="settingTheme" w={'16px'} />
            <Text className={cn(styles.menuItem)}>{t('common.theme')}</Text>
            <Image src={icChevronDown} alt="icChevronDown" w={'16px'} />
          </Box>
          <Box
            display={'grid'}
            alignItems={'center'}
            gridTemplateColumns={'20px 1fr'}
            gap={2}
            w={'100%'}
          >
            <div />
            <Flex gap={2}>
              {themeOptions.map((theme) => (
                <Box
                  key={theme.themeName}
                  onClick={() => handleChangeTheme(theme.themeName)}
                  className={cn(styles.themeItem)}
                  bg={`linear-gradient(139deg, ${theme.thumb[0]} 4.96%, ${theme.thumb[1]} 100.3%)`}
                />
              ))}
            </Flex>
          </Box>
        </MenuItem> */}
      </MenuList>
    </Menu>
  );
};

export default MenuPersonal;
