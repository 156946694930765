import { IClearAllConversation, IParams } from 'src/types';
import BaseRequest from './BaseRequest';
import config from 'src/config';

export interface IResponseSuccess<T> {
  data: T[];
  meta: {
    count: number;
  };
}

export default class ChatRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.baseUrlApi;
  }

  getChatConversations(payload: IParams) {
    const url = `/api/chat-conversations/`;
    return this.get(url, payload);
  }
  createChatConversations(payload: {
    temperature: number;
    model_type: string;
  }) {
    const url = `/api/chat-conversations/`;
    return this.post(url, payload);
  }

  getChatConversationById(id: number) {
    const url = `/api/chat-conversations/${id}`;
    return this.get(url);
  }
  deleteChatConversations(id: number) {
    const url = `/api/chat-conversations/${id}`;
    return this.delete(url);
  }
  sendChatMessage(payload: { content: string; conversation_id: string }) {
    const url = `/api/chat-messages/`;
    return this.post(url, payload);
  }
  getBots() {
    const url = `/api/bots/`;
    return this.get(url);
  }

  getFilePDF(bot_id: string, fileName: string) {
    const url = `/api/bots/${bot_id}/content/${fileName}`;
    return this.get(url);
  }

  clearAllConversation(payload: IClearAllConversation) {
    const url = `/api/chat-conversations/`;
    return this.delete(url, payload);
  }
}
