import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { emailValidation } from 'src/common/validationForm';
import AppButton from 'src/components/AppButton';
import styles from 'src/styles/page/Login.module.scss';
import * as yup from 'yup';
import EmailSvg from '../../../assets/images/email.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  // eslint-disable-next-line
  onSubmit: (values: any) => void;
  isLoading: boolean;
};

const schema = yup.object().shape({
  email: emailValidation,
});

const ResetForm = ({ onSubmit, isLoading = false }: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation();

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <Text
            className={cn(styles.title)}
            pt={4}
            pb={0}
            mb={4}
            textAlign={'center'}
          >
            {t('reset.title')}
          </Text>
          <Text py={2} className={cn(styles.description)} textAlign={'center'}>
            {t('reset.description')}
          </Text>
          <FormControl isInvalid={!!errors.email}>
            <InputGroup size="md" className={cn(styles.inputGroup)} mt={8}>
              <InputLeftElement
                className={cn(styles.inputIcon)}
                pointerEvents="none"
              >
                <img src={EmailSvg} alt="email icon" />
              </InputLeftElement>
              <Input
                className={cn(styles.input)}
                variant="outline"
                placeholder={t('login.email')}
                {...register('email')}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors?.email?.message as string}
            </FormErrorMessage>
          </FormControl>

          <AppButton
            borderRadius={'8px'}
            size="md"
            width={'full'}
            mt={6}
            variant="primary"
            mb={3}
            type="submit"
            className={cn(styles.btnSubmit, styles.btnContinue)}
            isLoading={isLoading}
            loadingText={t('reset.sendLoading')}
          >
            {t('reset.button.send')}
          </AppButton>
        </>
      </form>
    </Box>
  );
};

export default ResetForm;
