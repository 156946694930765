import { EAIMode, ESource } from 'src/types';

type HtmlParsedAnswer = {
  fragments: string[];
  citations: string[];
  followupQuestions: string[];
};

function isPdfFileName(str: string): boolean {
  return str.slice(str.length - 4).toLowerCase() === '.pdf';
}

export function parseAnswerToHtml(answer: string): HtmlParsedAnswer {
  const citations: string[] = [];
  const followupQuestions: string[] = [];

  // Extract any follow-up questions that might be in the answer
  let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
    followupQuestions.push(content);
    return '';
  });

  // trim any whitespace from the end of the answer after removing follow-up questions
  parsedAnswer = parsedAnswer.trim();

  // const parts = parsedAnswer.split(/\w+\.pdf/g);
  const regex = /([^[（\s]+(?:\.pdf))/g;

  const result = parsedAnswer.split(regex);
  const parts = result.filter((item) => item !== "");

  const fragments: string[] = parts.map((part, index) => {
    if (index % 2 === 0) {
      return part;
    } else {
      if (isPdfFileName(part)) {
        const { pageNumber, extension } = parsePageNumber(part);
        part =
          typeof pageNumber === 'number'
            ? part.replace(
                `${pageNumber}.${extension}`,
                `${pageNumber + 1}.${extension}`,
              )
            : part;
        if (!citations.includes(part)) {
          citations.push(part);
        }
      }
      return part;
    }
  });

  return {
    fragments,
    citations,
    followupQuestions,
  };
}

export const parsePageNumber = (fileName: string) => {
  const splitName = fileName.split('-');

  const tailFile = splitName[splitName.length - 1];
  const pageNumber = tailFile.split('.')[0];
  const extension = tailFile.split('.')[1];
  const filename =
    splitName.slice(0, splitName.length - 1).join('-') + `.${extension}`;

  return {
    pageNumber: Number.isInteger(Number(pageNumber))
      ? Number(pageNumber)
      : undefined,
    extension,
    filename,
  };
};

export const getNavigateRoute = (
  source: ESource,
  bot_id = '',
  chat_id?: string,
) => {
  const route =
    source === ESource.AZURE_OPENAI ? EAIMode.AZURE_OPENAI : EAIMode.CHATGPT;
  if (!chat_id) {
    return `${route}/${bot_id}`;
  }
  return `${route}/${bot_id}/${chat_id}`;
};

export const parseMessage = (message: string) => {
  return message.replaceAll('\\n', '<br/>').replaceAll('\n', '<br/>');
};
